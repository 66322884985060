.memo-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  box-sizing: border-box;
}

.memo-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
}

.memo-title {
  margin: 0;
  padding: 20px;
}

.memo-title-hr {
  width: 100%;
  border: none;
  border-top: 2px solid #000;
  margin: 0px 0 10px 0; /* 상단 여백은 10px, 하단 여백은 20px으로 조정 */
}

.memo-list {
  display: flex;
  justify-content: space-between; /* 왼쪽과 오른쪽 구역을 분리 */
  width: 100%;
}

.memo-column {
  display: flex;
  flex-direction: column;
  width: 48%; /* 좌우 구역이 동일한 너비를 가지도록 설정 */
  gap: 30px; /* 메모장 사이의 간격 설정 */
}

.memo-item {
  display: flex;
  flex-direction: column;
  gap: 30px;
  
}

.memo-textarea {
  width: 100%; /* 각 메모장이 부모 컨테이너의 너비를 가득 채우도록 설정 */
  height: 400px; /* 텍스트 영역의 높이를 조정 */
  resize: none;
  box-sizing: border-box;
  border: 5px solid #f5c5eb; 
  padding: 10px; /* 내부 여백 설정 */
  font-size: 19px;
  font-family: Arial, sans-serif; /* 폰트 설정 */
  border-radius: 5px;
}

.memo-textarea:focus {
  border-color: #f4369e; /* 포커스 시 테두리 색상을 검정으로 변경 */
  outline: none; /* 기본 outline 제거 */
}

.memo-button {  /*추가 버튼*/
  position: absolute; /* 절대 위치로 설정 */
  height: 35px;
  padding: 0 18px;
  top: 50px;
  right: 20px;
  background-color: #f4369e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 17px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}


.memo-button:hover {
  background-color: #e0246c;
}

.memo-delete-button {
  position: absolute;
  bottom: 10px;
  right: 10px;
  border: none;
  border-radius: 4px;
  padding: 5px 10px;
  cursor: pointer;
}


.memo-search-section {
  display: flex;
  justify-content: flex-end;  /* 오른쪽 정렬 */
  margin-bottom: 40px;        /* 아래쪽 여백 추가 */
  margin-right: 8px;
}

.memo-search-input {
  padding: 5px;
  margin-right: 7px;         /* 검색 버튼과 입력 필드 간격 */
  font-size: 15px;
}

.memo-search-button {
  padding: 8px 16px;
  cursor: pointer;
}

