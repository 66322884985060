/* 페이지 북마크 헤더 스타일 */
.page-bookmark-header {
    display: flex; /* 헤더를 가로로 배치 */
    border-bottom: 2px solid #000; /* 헤더 하단에 검은색 경계선 추가 */
    padding: 10px; /* 헤더의 내부 여백 설정 */
  }
  
  /* 페이지 북마크 제목 스타일 */
  .page-bookmark-title {
    margin-top: 20px; /* 외부 여백 제거 */
    cursor: pointer; /* 커서를 포인터로 변경 */
    padding: 10px; /* 내부 여백 설정 */
    font-size: 60px;
  }
  
  /* 페이지 북마크 탭 스타일 */
  .page-bookmark-tab {
    cursor: pointer; /* 커서를 포인터로 변경 */
    padding: 10px; /* 내부 여백 설정 */
    text-align: left; /* 텍스트를 왼쪽 정렬 */
    margin-top: 25px;
  }
  
  /* 활성화된 페이지 북마크 탭 스타일 */
  .page-bookmark-tab.active {
    border-bottom: 2px solid #000; /* 활성화된 탭 하단에 두꺼운 검은색 경계선 추가 */
  }
  
  /* 페이지 북마크 탭 제목 스타일 */
  .page-bookmark-tab-title {
    margin: 0; /* 외부 여백 제거 */
    display: inline; /* 인라인 요소로 설정 */
    font-size: 60px;
  }
  
  /* 페이지 북마크 탭 사이의 구분자 스타일 */
  .page-bookmark-separator {
    margin: 0 10px; /* 구분자 양 옆에 10px 여백 설정 */
    font-size: inherit; /* 부모 요소의 폰트 크기 상속 */
    line-height: inherit; /* 부모 요소의 줄 높이 상속 */
  }
  
  /* 페이지 북마크 콘텐츠 영역 스타일 */
  .page-bookmark-content {
    padding: 30px 30px 30px; /* 내부 여백 설정 */
  }
  
  /* 페이지 북마크 콘텐츠 내의 파일 및 텍스트 입력란 스타일 */
  .page-bookmark-content form input[type="file"],
  .page-bookmark-content form input[type="text"] {
    display: block; /* 블록 요소로 설정 */
   
  }
  
  /* 파일 업로드 폼 스타일 */
  .file-page-upload-form {
    display: flex;
    flex-direction: column; /* 요소들을 세로로 배치 */
    align-items: flex-start; /* 요소들을 왼쪽 정렬 */
    margin-top:40px;
  }
  
  /* 파일 선택 입력란을 숨기고, 레이블로 대체 */
  .file-page-upload-form input[type="file"] {
    display: none; /* 파일 선택 입력란 숨김 */
  }
  
  /* 커스텀 파일 선택 버튼 스타일 */
  .file-page-upload-label {
    display: inline-block; /* 인라인 블록 요소로 설정 */
    background-color: #ffffff; /* 배경색 설정 */
    color: rgb(0, 0, 0); /* 텍스트 색상 설정 */
    border-radius: 4px; /* 모서리 둥글게 설정 */
    cursor: pointer; /* 커서를 포인터로 변경 */
    font-size: 40px; /* 폰트 크기 설정 */
    font-weight: 580;
    border: none; /* 테두리 제거 */
    text-align: center; /* 텍스트 중앙 정렬 */
    transition: background-color 0.3s ease; /* 배경색 전환 효과 추가 */
  }
  
  /* 파일 선택 버튼 호버 스타일 */
  .file-page-upload-label:hover {
    background-color: #c5eef8; /* 호버 시 버튼 색 변경 */
  }
  
  /* 설명 입력란 스타일 */
  .file-page-description-input {
    margin-left: 0; /* 왼쪽 여백 제거 */
    height: 60px; /* 높이를 40px로 설정 */
    width: 1700px; /* 너비를 500px로 설정 */
    padding: 10px 8px; /* 입력란 내부 패딩 설정 */
    font-size: 25px; /* 폰트 크기 조정 */
    border: 1px solid #ccc; /* 테두리 추가 */
    border-radius: 4px; /* 모서리 둥글게 설정 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
  }
  
  /* 업로드 버튼 스타일 */
  .file-page-upload-button {
    margin-left: 10px; /* 왼쪽에 10px 여백 추가 */
    height: 60px; /* 버튼 높이 설정 */
    background-color: #f4369e; /* 버튼 배경색 설정 */
    color: white; /* 버튼 글씨 색상 설정 */
    border: none; /* 테두리 제거 */
    border-radius: 4px; /* 모서리 둥글게 설정 */
    cursor: pointer; /* 커서를 포인터로 변경 */
    font-size:27px; /* 폰트 크기 설정 */
    padding: 0px 20px; /* 내부 여백 조정 */
    display: flex; /* 요소를 플렉스로 설정하여 자식 요소 정렬 */
    align-items: center; /* 텍스트를 버튼 내부에서 수직 중앙 정렬 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
    position: relative; /* 상대 위치 설정 */
    white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
  }
  
  .file-page-description-wrapper {
    display: flex; /* 설명 입력란과 업로드 버튼을 가로로 배치 */
    align-items: center; /* 수직 정렬 */
    margin-top: 10px; /* 위쪽에 간격 추가 */
    margin-bottom: 45px;
  }
  
  .file-page-upload-button:hover {
    background-color: #e92566; /* 호버 시 버튼 색을 좀 더 어두운 색으로 변경 */
  }
  
  .file-page-cancel-button {
    margin-left: 10px; /* 파일 선택 버튼과 취소 버튼 사이에 간격 추가 */
    border: none; /* 테두리 제거 */
    border-radius: 4px; /* 모서리 둥글게 설정 */
    cursor: pointer; /* 커서를 포인터로 변경 */
    padding: 2px 8px; /* 버튼의 내부 여백 설정 */
    font-size: 18px; /* 버튼 글씨 크기 설정 */
    display: none; /* 기본적으로 숨김 */
    white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
  }
  
  .file-page-upload-wrapper:hover .file-page-cancel-button {
    display: inline-block; /* 파일 선택 버튼에 마우스를 올리면 취소 버튼 표시 */
  }
  
  /* 파일 북마크 목록 스타일 */
  .file-page-bookmarks {
    display: flex; /* 요소들을 가로로 배치 */
    flex-wrap: wrap; /* 아이템들을 여러 줄로 배치 */
    gap: 20px 26px; /* 아이템 간 간격 설정 */
  }
  
  /* 개별 파일 북마크 스타일 */
  .file-page-bookmark {
    position: relative; /* 상대 위치 설정 */
    width: calc(25% - 20px); /* 너비 설정 */
    border: 5px solid #f5c5eb; /* 검은색 테두리 설정 */
    padding: 10px; /* 내부 여백 설정 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
    height: 155px; /* 기본 블록 높이 설정 */
    overflow: hidden; /* 넘치는 내용 숨김 */
    transition: height 0.3s ease; /* 높이 전환 효과 추가 */
    cursor: pointer; /* 커서를 포인터로 변경 */
    font-size: 24px;
    border-radius: 10px; /* 버튼 모서리를 둥글게 설정 */
  }
  
  /* 파일 북마크에 마우스를 올렸을 때 테두리 색상 변경 */
  .file-page-bookmark:hover {
    border-color: #ee42c3; /* 원하는 색상으로 변경 */
  }

  /* 확장된 파일 북마크 스타일 */
  .file-page-bookmark.expanded {
    height: auto; /* 확장된 상태에서 높이 자동 조정 */
  }
  
  /* 파일 북마크 텍스트 스타일 */
  .file-page-bookmark p {
    margin: 5px 0; /* 위아래 5px 여백 설정 */
    white-space: pre-wrap; /* 줄바꿈과 공백을 그대로 표시 */
  }
  
  /* 파일 이름 스타일 */
  .file-page-bookmark .file-page-name {
    font-weight: bold; /* 파일 이름을 굵게 설정 */
    overflow: hidden; /* 넘치는 텍스트 숨김 */
    text-overflow: ellipsis; /* 넘치는 텍스트를 말줄임표로 표시 */
    display: block; /* 블록 요소로 설정 */
    width: 100%; /* 너비를 100%로 설정 */
    margin-bottom: 20px; /* 아래쪽에 20px 여백 추가 */
  }
  
  /* 확장된 파일 이름 스타일 */
  .file-page-bookmark.expanded .file-page-name {
    white-space: normal; /* 공백을 정상적으로 처리 */
    overflow: visible; /* 넘치는 텍스트 표시 */
    text-overflow: clip; /* 말줄임표 제거 */
  }
  
  /* 파일 북마크 버튼 스타일 */
  .file-page-bookmark .file-page-buttons {
    display: none; /* 기본적으로 버튼 숨김 */
    position: absolute; /* 절대 위치 설정 */
    bottom: 0; /* 부모 요소의 아래쪽에 위치 */
    right: 0; /* 부모 요소의 오른쪽에 위치 */
    border: none; /* 버튼 테두리 제거 */
    border-radius: 4px; /* 버튼 모서리를 둥글게 설정 */
    cursor: pointer; /* 마우스 커서를 손가락 모양으로 변경 */
    font-size: 17px;
  }
  
  /* 파일 북마크에 마우스를 올리면 버튼 표시 */
  .file-page-bookmark:hover .file-page-buttons {
    display: block; /* 버튼 표시 */
  }
  
  /* edit-textarea 클래스 스타일링 */
  .file-page-edit-textarea {
    width: 100%; /* 텍스트 영역의 너비를 100%로 설정 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
    resize: none; /* 사용자가 크기 조정하지 못하게 설정 */
    padding: 8px 12px; /* 내부 여백 설정 */
    font-size: 22px; /* 폰트 크기 설정 */
    border: 1px solid #ccc; /* 기본 테두리 설정 */
    border-radius: 4px; /* 모서리 둥글게 설정 */
    font-family: inherit; /* 부모 요소의 폰트 상속 */
    outline: none; /* 포커스 시 기본 테두리 제거 */
  }
  
  /* 파일 북마크 수정 텍스트 영역 스타일 */
  .file-page-bookmark .file-page-edit-textarea {
    width: 100%; /* 너비를 100%로 설정 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
    resize: none; /* 사용자가 크기 조정하지 못하게 설정 */
    min-height: 100px; /* 최소 높이를 100px로 설정 */
  }

  .file-page-restore-button {  /*추가 버튼*/
    position: absolute; /* 절대 위치로 설정 */
    top: 120px; /* 버튼을 상단에서 20px 아래로 */
    right: 45px; /* 버튼을 오른쪽에서 20px 왼쪽으로 이동 */
    height: 50px;
    background-color: #f4369e;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 22px; /* 폰트 크기 설정 */
    padding: 5px 40px; /* 내부 여백 조정 */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }

  .file-page-search-section {
    display: flex;
    justify-content: flex-end;  
    margin-bottom: 15px;      
  }
  
  .file-page-search-input {
    padding: 10px;
    margin-right: 10px;        
    font-size: 22px;
  }
  
  .file-page-search-button {
    padding: 10px 25px;
    font-size: 22px;
    cursor: pointer;
  }
  
  
  /* 목록 스타일 */
  ul {
    list-style-type: none; /* 기본 목록 스타일 제거 */
    padding: 0; /* 패딩 제거 */
  }
  
  /* 목록 항목 스타일 */
  li {
    margin-bottom: 20px; /* 아래쪽에 20px 여백 추가 */
  }
  
  /*------------------------------------------------------------*/


  /* 사이트 업로드 폼 스타일 */
.site-page-upload-form {
  margin-top: 70px;
  display: flex;
  flex-direction: column;
}

/* URL 입력 필드와 버튼을 같은 줄에 배치 */
.site-page-upload-url-wrapper {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

/* 사이트 업로드 입력란 스타일 */
.site-page-upload-form input[type="text"],
.site-page-upload-form input[type="url"] {
  height: 60px;
  padding: 8px 15px;
  box-sizing: border-box;
  width: 1000px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-size: 22px;
  transition: border-color 0.3s ease;
}

.site-page-upload-form input[type="text"]:focus,
.site-page-upload-form input[type="url"]:focus {
  border-color: #f4369e;
  outline: none;
}


/* 사이트 업로드 버튼 스타일 */
.site-page-upload-form button {
  height: 60px;
  padding: 0px 13px; /* 내부 여백 조정 */
  background-color: #f4369e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 22px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  white-space: nowrap; /* 텍스트가 줄바꿈되지 않도록 설정 */
}

.site-page-upload-form button:hover {
  background-color: #e0246c;
}

/* 사이트 북마크 목록 스타일 */
.site-page-bookmarks {
  display: flex;
  flex-wrap: wrap;
  gap: 20px 25px;
  margin-top: 50px;
}

/* 개별 사이트 북마크 스타일 */
.site-page-bookmark {
  position: relative;
  width: calc(25% - 20px);
  box-sizing: border-box;
  min-height: 120px; /* 최소 높이 설정 */
  max-height: 200px; /* 최대 높이 설정 (필요에 따라 조정) */
  border-radius: 8px;
  background-color: #ffe8fd;
  transition:  background-color 0.3s ease, height 0.3s ease;
  display: flex;
  flex-wrap: wrap;
  padding: 5px;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* 넘치는 내용 숨기기 */
}

.site-page-bookmark:hover {
  background-color: hsl(346, 100%, 83%);
  overflow: visible; /* 호버 시 넘치는 내용 표시 */
}

/* 사이트 이름 스타일 */
.site-page-bookmark .site-page-name {
  font-weight: bold;
  text-decoration: none;
  font-size: 28px;
  transition: color 0.3s ease;
  text-align: center;
  width: 100%; /* 사이트 이름이 블록 너비에 맞게 설정 */
}

/* 사이트 북마크 입력란 스타일 */
.site-page-bookmark input[type="text"],
.site-page-bookmark input[type="url"] {
  width: 100%;
  box-sizing: border-box;
  margin-bottom: 10px;
  padding: 8px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 21px;
}

/* 사이트 북마크 버튼 스타일 */
.site-page-bookmark .site-page-buttons {
  display: none;
  position: absolute;
  bottom: 0px;
  right: 0px;
}

/* 사이트 북마크 버튼들 간의 간격 설정 */
.site-page-bookmark .site-page-buttons {
  border: none;
  padding: 5px 5px;
  border-radius: 4px;
  font-size: 12px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

/* 마우스를 올리면 삭제 버튼 표시 */
.site-page-bookmark:hover .site-page-buttons {
  display: inline-block; /* 호버 시 버튼 표시 */
}

.site-page-restore-button {  /*추가 버튼*/
  position: absolute; /* 절대 위치로 설정 */
  top: 120px; /* 버튼을 상단에서 20px 아래로 */
  right: 45px; /* 버튼을 오른쪽에서 20px 왼쪽으로 이동 */
  height: 50px;
  background-color: #f4369e;
  color: white;
  border: none;
  border-radius: 5px;
  font-size: 22px; /* 폰트 크기 설정 */
  padding: 5px 40px; /* 내부 여백 조정 */
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.site-page-search-section {
  display: flex;
  justify-content: flex-end;  
  margin-bottom: 15px;      
}

.site-page-search-input {
  padding: 10px;
  margin-right: 10px;        
  font-size: 22px;
}

.site-page-search-button {
  padding: 10px 25px;
  font-size: 22px;
  cursor: pointer;
}