.todo-container {
  display: flex; /* 컨테이너를 가로로 나란히 배치 */
  height: 100%; /* 높이를 부모 요소에 맞춤 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
  overflow: hidden; /* 전체 컨테이너의 스크롤을 숨김 */
  overflow-x: hidden; /* 가로 스크롤을 숨김 */
}

.todo-left {
  flex: 0.9; /* 왼쪽 구역을 넓게 설정 */
  border-right: 1px solid #ccc; /* 오른쪽에 경계선 추가 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
  overflow: hidden; /* 왼쪽 구역의 스크롤을 숨김 */
  overflow-x: hidden; /* 가로 스크롤을 숨김 */
  position: relative; /* 제목 등을 고정하기 위해 상대 위치 설정 */
  padding: 20px; /* 내부 여백을 20px로 설정 */
}

.todo-left-content {
  padding-top: 20px; /* 제목과 날짜 아래의 공백을 줄이기 위해 위쪽 여백을 30px로 조정 */
  height: calc(100% - 50px); /* 날짜와 제목의 높이를 뺀 나머지 영역을 리스트로 사용 */
  overflow-y: auto; /* 세로 스크롤을 허용 */
  overflow-x: hidden; /* 가로 스크롤을 숨김 */
  margin-right: -33px; /* 세로 스크롤 오른족 블록 끝에 붙임 */
}

.todo-left h1 {
  position: absolute; /* 고정 위치 설정 */
  top: 0; /* 위쪽에 고정 */
  left: 10px; /* 왼쪽에서 약간 떨어진 위치에 고정 */
  z-index: 1; /* 다른 요소보다 앞에 보이도록 설정 */
  margin: 0; /* 여백 제거 */
  padding: 10px; /* 제목 내부 여백 설정 */
  width: calc(100% - 20px); /* 제목 너비를 설정하여 좌우 여백을 확보 */
}

.todo-left .title-hr {
  position: absolute; /* 고정 위치 설정 */
  top: 70px; /* 제목 아래에 위치 */
  left: 0; /* 왼쪽에 고정 */
  width: 100%; /* 가로로 전체 길이 차지 */
  margin: 0; /* 여백 제거 */
  border: none; /* 기본 경계선 제거 */
  border-top: 1px solid #ccc; /* 윗부분에만 경계선 추가 */
  z-index: 1; /* 다른 요소보다 앞에 보이도록 설정 */
}

.todo-right {
  flex: 1; /* 오른쪽 구역을 나머지 공간으로 채움 */
  display: flex; /* 내부 요소들을 플렉스 레이아웃으로 배치 */
  flex-direction: column; /* 세로 방향으로 배치 */
  padding: 10px; /* 내부 여백 설정 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
  overflow: hidden; /* 스크롤 숨김 */
  overflow-x: hidden; /* 가로 스크롤을 숨김 */
  height: 100%; /* 높이를 부모 요소에 맞춤 */
  position: relative; /* 제목 등을 고정하기 위해 상대 위치 설정 */
}

.todo-subcontainer {
  display: flex; /* 내부 요소들을 가로로 나란히 배치 */
  flex: 1; /* 서브컨테이너를 남은 공간으로 채움 */
  gap: 10px; /* 각 서브 섹션 간의 간격 설정 */
  overflow: hidden; /* 스크롤 숨김 */
  overflow-x: hidden; /* 가로 스크롤을 숨김 */
  height: 100%; /* 높이를 부모 요소에 맞춤 */
}

.todo-subsection {
  flex: 1; /* 각 서브 섹션을 동일한 비율로 나눔 */
  border: 1px solid #ccc; /* 테두리 설정 */
  padding: 10px; /* 내부 여백 설정 */
  border-radius: 4px; /* 테두리 모서리를 둥글게 설정 */
  overflow-y: auto; /* 세로 스크롤을 허용 */
  overflow-x: hidden; /* 가로 스크롤을 숨김 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
  height: 100%; /* 높이를 부모 요소에 맞춤 */
  position: relative; /* 제목 등을 고정하기 위해 상대 위치 설정 */
  padding-top: 60px; /* 제목 높이만큼 위쪽 여백 추가 */
}

.todo-subsection .section-title {
  position: absolute; /* 고정 위치 설정 */
  top: 0; /* 위쪽에 고정 */
  left: 10px; /* 왼쪽에서 약간 떨어진 위치에 고정 */
  background-color: #fff; /* 배경색 설정 (필요시) */
  z-index: 1; /* 다른 요소보다 앞에 보이도록 설정 */
  margin: 0; /* 여백 제거 */
  padding: 10px; /* 제목 내부 여백 설정 */
  width: calc(100% - 20px); /* 제목 너비를 설정하여 좌우 여백을 확보 */
}

.todo-subsection .title-hr {
  position: absolute; /* 고정 위치 설정 */
  top: 50px; /* 제목 아래에 위치 */
  left: 0; /* 왼쪽에 고정 */
  width: 100%; /* 가로로 전체 길이 차지 */
  margin: 0; /* 여백 제거 */
  border: none; /* 기본 경계선 제거 */
  border-top: 1px solid #ccc; /* 윗부분에만 경계선 추가 */
  z-index: 1; /* 다른 요소보다 앞에 보이도록 설정 */
}

/* 스크롤바 기본 숨기기 및 필요 시 나타나게 하기 */
.todo-left-content::-webkit-scrollbar,
.todo-subsection::-webkit-scrollbar {
  width: 0; /* 기본적으로 스크롤바를 숨김 */
}

.todo-left-content:hover::-webkit-scrollbar,
.todo-subsection:hover::-webkit-scrollbar {
  width: 8px; /* 마우스를 올리면 나타나는 스크롤바 너비 */
}

.todo-left-content::-webkit-scrollbar-thumb,
.todo-subsection::-webkit-scrollbar-thumb {
  background: #888; /* 스크롤바 색상 설정 */
  border-radius: 4px; /* 스크롤바 모서리를 둥글게 설정 */
}

.todo-inputs {
  display: flex; /* 입력 필드와 버튼을 가로로 나란히 배치 */
  gap: 10px; /* 각 입력 필드 사이의 간격 설정 */
  margin-bottom: 10px; /* 아래쪽 여백 추가 */
}

.todo-inputs input[type="date"] {
  width: 120px; /* 날짜 입력 필드의 너비 설정 */
}

.todo-inputs input[type="text"] {
  flex: 1; /* 남은 공간을 차지하여 입력 필드 확장 */
  padding: 5px; /* 내부 여백 설정 */
  font-size: 15px; /* 글씨 크기 설정 */
}

.todo-inputs button {
  padding: 5px 10px; /* 버튼의 내부 여백 설정 */
  background-color: #f4369e; /* 버튼 배경색을 빨간색으로 변경 */
  color: white; /* 버튼 글씨 색을 흰색으로 변경 */
  border: none; /* 테두리 제거 */
  border-radius: 4px; /* 모서리 둥글게 설정 */
}

.todo-inputs button:hover {
  background-color: #e92566; /* 호버 시 버튼 색을 좀 더 어두운 녹색으로 변경 */
}

.full-width-hr {
  border: none; /* 기본 경계선 제거 */
  border-top: 1px solid #ccc; /* 윗부분에만 경계선 추가 */
  margin: 10px 0; /* 위아래 여백 설정 */
  width: 100%; /* 가로로 전체 길이 차지 */
}

.date-title {
  text-align: left; /* 텍스트를 왼쪽 정렬 */
  margin: 10px 0; /* 위아래 여백 설정 */
}

.todo-item {
  list-style-type: disc; /* 리스트 아이템 앞에 점 스타일 설정 */
  margin-left: 20px; /* 왼쪽 여백 설정 */
  margin-right:40px;
  text-align: left; /* 텍스트를 왼쪽 정렬 */
  position: relative; /* 버튼을 위치시키기 위해 상대 위치 설정 */
  cursor: pointer; /* 마우스를 올리면 클릭 가능한 스타일 추가 */
  font-size: 20px; /* 작성된 글의 글씨 크기 증가 */
  word-break: break-word; /* 단어가 블록 경계를 넘을 때 자동으로 줄바꿈 */
  overflow-wrap: break-word; /* 단어가 블록 경계를 넘을 때 자동으로 줄바꿈 */
  white-space: normal; /* 긴 텍스트를 줄 바꿈하여 아래로 이어지게 함 */
}

/* 이전 Todo와 이후 Todo의 글 사이 간격을 줄이기 위한 스타일 */
.todo-subsection .todo-item {
  margin-bottom: 5px; /* 글 사이의 간격을 줄이기 위해 아래 여백을 5px로 설정 */
}

.completed {
  text-decoration: line-through; /* 완료된 항목에 취소선 추가 */
  color: grey; /* 완료된 항목의 글자색을 회색으로 변경 */
}

.todo-list {
  padding: 0; /* 리스트의 기본 여백 제거 */
  margin: 0; /* 리스트의 기본 여백 제거 */
  height: 100%; /* todo-list에 높이 설정 */
  overflow-y: auto; /* 개별 항목에 세로 스크롤바 추가 */
  overflow-x: hidden; /* 가로 스크롤을 숨김 */
}

.todo-textarea {
  width: 95%; /* textarea를 가로로 확장 */
  height: 50px; /* textarea의 기본 세로 길이를 40px로 설정 */
  resize: none; /* 사용자가 크기를 조절하지 못하도록 설정 */
  font-size: 20px; /* 작성된 글씨와 동일한 크기 */
  padding: 3px 5px; /* 내부 여백을 줄여서 높이를 감소 */
  border: 1px solid #ccc; /* 기본 테두리 설정 */
  border-radius: 4px; /* 테두리 모서리를 약간 둥글게 설정 */
  box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
  font-family: inherit; /* 부모 요소와 동일한 글꼴 사용 */
  line-height: 1.2; /* 줄 간격을 줄여서 세로 길이를 감소 */
  outline: none; /* 포커스 시 기본 테두리 제거 */
}


.date-title {
  display: flex; /* 날짜 제목과 삭제 버튼을 가로로 배치 */
  align-items: center; /* 수직 중앙 정렬 */
  justify-content: space-between; /* 요소 사이에 공간을 균등하게 분배 */
  font-size: 18px; /* 날짜 텍스트 크기 설정 (여기서 크기를 조정할 수 있습니다) */
  margin: 10px 0 5px 0; /* 위아래 여백 설정 */
}


.date-delete-button {
  display: none; /* 기본적으로 버튼을 숨김 */
  margin-left: 10px; /* 버튼과 날짜 사이의 간격 설정 */
  padding: 2px 8px; /* 버튼의 내부 여백 설정 */
  font-size: 14px; /* 버튼 글씨 크기 설정 */
  border: none; /* 버튼 테두리 제거 */
  border-radius: 4px; /* 버튼 모서리를 둥글게 설정 */
  cursor: pointer; /* 마우스 커서를 손가락 모양으로 변경 */
}

.date-title:hover .date-delete-button {
  display: inline-block; /* 마우스를 올리면 버튼이 보이게 함 */
}


.date-display { /*오늘todo 리스트*/
  text-align: right; /* 텍스트를 오른쪽 정렬 */
  margin-top: 60px; /* 위쪽 여백을 20px로 조정 */
  font-size: 18px; /* 날짜와 요일의 글씨 크기 설정 */
  font-weight: bold;
  display: block; /* 요소를 화면에 표시 */
}


