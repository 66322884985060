/* 로그인 홈 페이지 스타일 */
.login-home-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh;  /* 화면 전체 높이 */
    background-color: #f5f5f5;  /* 페이지 배경 색 */
}

.login-title {
    font-size: 48px;  /* 큰 글씨 */
    font-weight: bold;
    margin-bottom: 40px;  /* 제목 아래 여백 */
    color: #333;  /* 글씨 색 */
}

.button-container {
    display: flex;
    flex-direction: column;
    gap: 20px;  /* 버튼 간격 */
}

.login-button, .signup-button {
    width: 200px;
    height: 50px;
    font-size: 18px;
    background-color:#a1266a;  /* 녹색 버튼 */
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.login-button:hover, .signup-button:hover {
    background-color: #a1266a;  /* 버튼 호버 효과 */
}

/* 로그인 페이지 중앙 정렬을 위한 스타일 */
.auth-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100vh; /* 화면 전체 높이 */
    background-color: #f5f5f5;  /* 배경색 */
}

.auth-form {
    background-color: #fff;  /* 폼 배경색 */
    padding: 20px 40px 20px 20px;  /* 내부 여백 (오른쪽 여백을 더 크게) */
    border-radius: 10px;  /* 모서리 둥글게 */
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);  /* 약간의 그림자 */
    width: 400px;  /* 폼의 너비를 400px로 설정 */
}

.auth-form div {
    margin-bottom: 15px;
}

.auth-form input {
    width: 100%;  /* 입력 필드가 폼 너비에 맞도록 */
    padding: 10px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

.auth-form button {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    background-color:#a1266a;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.auth-form button:hover {
    background-color: #a1266a;
}
