
/* 전체 애플리케이션 컨테이너 레이아웃 설정 */
.app-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 10px;
  height: 100vh;
  padding: 10px;
  box-sizing: border-box;
  overflow: hidden; /* 전체 컨테이너의 스크롤을 제거 */
}

/* 각 섹션 스타일링 */
.section {
  border: 1px solid #ccc;
  padding: 10px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  overflow-y: auto; /* 내용이 많을 경우 스크롤 가능 */
  overflow-x: hidden; /* 가로 스크롤 제거 */
  box-sizing: border-box;
  height: 100%; /* 각 섹션이 컨테이너의 높이를 채우도록 설정 */
  position: relative; /* 제목 고정을 위한 상대 위치 설정 */
}