/* 공통 스타일 */
.page-title {
    border-bottom: 2px solid black;
    padding-bottom: 20px;
    margin-bottom: 10px;
    margin: 0;
    font-size: 60px;
    padding: 40px; /* 헤더의 내부 여백 설정 */
  }
  
  .add-project-page {
    display: flex;
    gap: 8px;
    margin-bottom: 40px;
    margin-top: 60px;
    margin-left: 40px;
  }
  
  .add-project-page input {
    margin-left:30px;
    height: 70px; /* 높이를 40px로 설정 */
    width: 1800px; /* 너비를 500px로 설정 */
    padding: 10px 8px; /* 입력란 내부 패딩 설정 */
    font-size: 27px; /* 폰트 크기 조정 */
    border: 1px solid #ccc; /* 테두리 추가 */
    border-radius: 4px; /* 모서리 둥글게 설정 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
  }
  
  .add-project-page button {
    margin-left: 3px; /* 왼쪽에 10px 여백 추가 */
    height: 70px; /* 버튼 높이 설정 */
    background-color: #f4369e; /* 버튼 배경색 설정 */
    color: white; /* 버튼 글씨 색상 설정 */
    border: none; /* 테두리 제거 */
    border-radius: 4px; /* 모서리 둥글게 설정 */
    cursor: pointer; /* 커서를 포인터로 변경 */
    font-size: 27px; /* 폰트 크기 설정 */
    padding: 0px 20px; /* 내부 여백 조정 */
    display: flex; /* 요소를 플렉스로 설정하여 자식 요소 정렬 */
    align-items: center; /* 텍스트를 버튼 내부에서 수직 중앙 정렬 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
    position: relative; /* 상대 위치 설정 */
  }
  
  .add-project-page button:hover {
    background-color: #e92566;
  }
  
  .project-page-list {
    padding:50px;
  }
  
  /* 프로젝트 제목 스타일 */
  .project-page-title {
    cursor: pointer;
    padding: 30px;
    background-color: #ffbfe2;
    margin-bottom: 10px;
    position: relative; /* 상대적 위치 지정 */
    font-size: 28px; /* 폰트 크기 설정 */
    border-radius: 7px; /* 모서리 둥글게 설정 */
  }
  
  
  .project-page-title:hover {
    background-color: #ffbfe2;
  }
  
  /* 프로젝트 제목에서 삭제 버튼 스타일 */
  .project-page-title .delete-button {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  /* .delete-button 클래스를 가진 span 요소가 있는 프로젝트 제목에 마우스를 올렸을 때 */
  .project-page-title:hover .delete-button {
    display: block;
  }
  
  .project-page-title .edit-input {
    width: calc(100% - 20px);
    padding: 10px;
    font-size: 28px;
    border: 1px solid #ccc;
    background-color: #fff;
    border-radius: 5px;
    box-sizing: border-box;
  }
  
  /* 입력 필드에 포커스될 때 스타일 변경 */
  .project-page-title .edit-input:focus {
    border: 1px solid #ffffff; /* TaskDetail의 배경색 설정 */
    outline: none; /* 기본 outline 제거 */
  }
  
  
  .project-page-details {
    margin-left: 40px;
    padding: 10px;
    border-left: 2px solid #ccc;
  }

  .project-page-title .complete-button {
    display: none;
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 18px;
  }
  
  /* .delete-button 클래스를 가진 span 요소가 있는 프로젝트 제목에 마우스를 올렸을 때 */
  .project-page-title:hover .complete-button {
    display: block;
  }
  
  
  
  /* Task 목록의 각 Task 스타일 */
  .task-page-list .task-page {
    font-size: 24px; /* 폰트 크기 설정 */
    padding: 18px; /* Task 내부 여백 설정 */
    margin: 0px 0; /* Task 간의 간격 설정 */
    border: 4px solid #ffe4f3; /* Task의 배경색 설정 */
    border-radius: 5px; /* Task의 모서리를 둥글게 설정 */
    position: relative; /* 자식 요소의 절대 위치 지정에 대비한 상대 위치 설정 */
    cursor: default; /* 기본 커서 모양 설정 */
  }
  
  /* Task 입력 및 수정 필드 스타일 */
  .task-page-input,
  .task-page-list .task-page input,
  .task-page-list .task-page textarea {
    width: calc(100% - 27px); /* 필드의 너비를 설정하여 버튼과 함께 배치 */
    font-size: 24px; /* 폰트 크기 설정 */
    padding: 10px; /* Task 내부 여백 설정 */
    margin: 2px 0; /* Task 간의 간격 설정 */
    border: 4px solid #ffe4f3; /* Task의 배경색 설정 */
    border-radius: 5px; /* Task의 모서리를 둥글게 설정 */
    position: relative; /* 자식 요소의 절대 위치 지정에 대비한 상대 위치 설정 */
    cursor: default; /* 기본 커서 모양 설정 */
  }
  
  /* 입력 필드에 포커스(클릭)될 때 스타일 변경 */
  .task-page-input:focus,
  .task-page-list .task-page input:focus,
  .task-page-list .task-page textarea:focus {
    border: 4px solid #ffe4f3; /* Task의 배경색 설정 */
    outline: none; /* 기본 outline 제거 */
  }
  
  
  /* Task 저장 버튼 스타일 */
  .task-page-save-button {
    margin-left: 1px; /* 왼쪽 여백 설정 */
    height: 30px; /* 버튼의 높이 설정 */
    background-color: #f4369e; /* 버튼의 배경색 설정 */
    color: white; /* 버튼 텍스트 색상 설정 */
    border: none; /* 버튼 테두리 제거 */
    border-radius: 4px; /* 버튼의 모서리를 둥글게 설정 */
    cursor: pointer; /* 버튼에 마우스를 올렸을 때 포인터로 변경 */
    padding: 5px 13px; /* 버튼 내부 여백 조정 */
    display: flex; /* 버튼 내부의 텍스트를 수평으로 정렬하기 위한 플렉스 박스 사용 */
    align-items: center; /* 버튼 내부의 텍스트를 수직 중앙 정렬 */
    box-sizing: border-box; /* 패딩과 테두리를 포함한 전체 크기 계산 */
    position: relative; /* 상대 위치 설정 */
  }
  
  /* Task 저장 버튼 호버 효과 */
  .task-page-save-button:hover {
    background-color: #e92566; /* 호버 시 버튼 배경색 변경 */
  }
  
  
  
  .task-page-delete-button {
    position: absolute;
    right: 10px;
    top:50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    font-size: 16px;
    cursor: pointer;
  }
  .task-page-delete-button:hover {
    display: block;
  }
  
  .task-page-complete-button {
    border: none;
    transform: translateY(-90%);
    border-radius: 4px;
    padding: 5px 10px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-left: auto;
    font-size: 16px;
  }
  
  /* 완료된 작업의 취소선 스타일 */
  .task-page span.completed {
    text-decoration: line-through;
    color: gray; /* 취소선 색상 */
  }
  
  
  /* Task 목록의 텍스트 입력 필드 및 텍스트 영역 스타일 */
  .task-page-list .task-page textarea,
  .task-detail-page-item textarea {
    resize: none; /* 사용자가 텍스트 영역의 크기를 조정하지 못하도록 설정 */
    height: auto; /* 텍스트 영역의 높이를 자동으로 설정 */
  }
  
  /* Task 상세 영역 스타일 */
  /* TaskDetail 입력 및 수정 필드 스타일 */
  .task-detail-page-input,
  .task-detail-page-item .task-detail-page-input,
  .task-detail-page-item .task-detail-page-textarea {
    width: calc(100% - 27px); /* 필드의 너비를 설정하여 버튼과 함께 배치 */
    padding: 10px; /* TaskDetail 내부 여백 설정 */
    margin: 2px 0; /* TaskDetail 간의 간격 설정 */
    border: 4px solid #ffe4f3; /* TaskDetail의 배경색 설정 */
    border-radius: 5px; /* TaskDetail의 모서리를 둥글게 설정 */
    position: relative; /* 자식 요소의 절대 위치 지정에 대비한 상대 위치 설정 */
    cursor: default; /* 기본 커서 모양 설정 */
    font-size:23px;
  }
  
  /* TaskDetail 입력 필드에 포커스(클릭)될 때 스타일 변경 */
  .task-detail-page-input:focus,
  .task-detail-page-item .task-detail-page-input:focus,
  .task-detail-page-item .task-detail-page-textarea:focus {
    border: 4px solid #ffe4f3; /* TaskDetail의 배경색 설정 */
    outline: none; /* 기본 outline 제거 */
  }
  
  /* TaskDetail 상세 영역 스타일 */
  .task-details-page {
    margin-left: 30px;
    padding: 10px;
    border-left: 2px solid #ccc;
  }
  
  /* TaskDetail 상세 아이템 스타일 */
  .task-detail-page-item {
    position: relative;
    padding: 5px;
    margin: 5px 0;
    border-bottom: 1px solid #ddd;
    font-size:23px;
  }
  
  /* TaskDetail 삭제 버튼 스타일 */
  .task-detail-page-delete-button {
    position: absolute;
    right: 10px;
    top: 50%;
    transform: translateY(-50%);
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
  }
  
  /* TaskDetail 아이템에 마우스를 올리면 삭제 버튼 표시 */
  .task-detail-page-delete-button:hover {
    display: block;
  }
  
  /* TaskDetail 완료된 항목 스타일 */
  .task-detail-page-item.completed span {
    text-decoration: line-through; /* 중간선 */
    color: gray; /* 글씨 색상 변경 */
  }
  
  