.memo-page-container {
    display: flex;
    flex-direction: column;
    width: 100%;
    box-sizing: border-box;
  }
  
  .memo-page-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }
  
  .memo-page-title {
    margin: 0;
    font-size: 60px;
    padding: 40px; /* 헤더의 내부 여백 설정 */
  }
  
  .memo-page-title-hr {
    width: 100%;
    border: none;
    border-top: 2px solid #000;
    margin: 0px 0 10px 0; /* 상단 여백은 10px, 하단 여백은 20px으로 조정 */
  }
  
  .memo-page-list {
    display: flex;
    justify-content: space-between;
    width: 100%;
    padding: 0 70px; /* 왼쪽과 오른쪽에 각각 100px의 패딩 추가 */
    margin-bottom: 100px; /* 하단 공백 추가 */
    box-sizing: border-box;
  }
  
  .memo-page-column {
    width: 49%; /* 좌우 구역이 동일한 너비를 가지도록 설정 */
  }
  
  .memo-page-textarea {
    width: 100%; 
  }
  
  .memo-page-item {
    display: flex;
    flex-direction: column;
    position: relative; /* 삭제 버튼이 제대로 정렬되도록 부모 요소의 상대적 위치 설정 */
  }

  .memo-page-textarea {
    width: 100%; /* 부모 요소의 너비를 가득 채움 */
    height: 700px; /* 텍스트 영역의 높이를 조정 */
    box-sizing: border-box;
    border: 5px solid #f5c5eb; 
    padding: 20px; /* 내부 여백 설정 */
    font-size: 24px;
    font-family: Arial, sans-serif; /* 폰트 설정 */
    border-radius: 8px;
    margin-top: 20px; /* 텍스트 상단과 하단 여백 추가 */
    margin-bottom: 40px;
  }


  .memo-page-textarea:focus {
    border-color: #f4369e; /* 포커스 시 테두리 색상을 검정으로 변경 */
    outline: none; /* 기본 outline 제거 */
  }
  
  .memo-page-button {  /*추가 버튼*/
    position: absolute; /* 절대 위치로 설정 */
    top: 100px; /* 버튼을 상단에서 20px 아래로 */
    right: 45px; /* 버튼을 오른쪽에서 20px 왼쪽으로 이동 */
    height: 50px;
    background-color: #f4369e;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 22px; /* 폰트 크기 설정 */
    padding: 5px 40px; /* 내부 여백 조정 */
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .memo-page-button:hover {
    background-color: #e0246c;
  }
  
  .memo-page-delete-button {
    position: absolute;
    bottom: 30px; /* 부모 요소 하단에서 20px 위로 */
    right: 20px; /* 부모 요소 오른쪽에서 20px 왼쪽으로 */
    border: none;
    border-radius: 4px;
    padding: 5px 10px;
    cursor: pointer;
    font-size: 16px;
  }
  

  .memo-page-search-section {
    display: flex;
    justify-content: flex-end;  /* 오른쪽 정렬 */
    margin-bottom: 70px;        /* 아래쪽 여백 추가 */
    margin-right: 30px;
  }
  
  .memo-page-search-input {
    padding: 10px;
    margin-right: 10px;        
    font-size: 22px;
  }
  
  .memo-page-search-button {
    padding: 10px 25px;
    font-size: 22px;
    cursor: pointer;
  }
  
  